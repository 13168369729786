<template >
  <div class="calendar-cont">
      <div v-if="backgr !== 'No se encontraron coincidencias'">
        <img :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image" alt="pc" class="pc imgx">
        <img :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image_mv" alt="mo" class="mo imgx">
      </div><!---->
      <div class="noav" v-else>

      </div>
    <div class="calendar-header">
      
    </div>
    <Tabs noTouch="true">
      <TabItem id="primer-equipo" >
        <template #name>
          <div class="team-type-header" id="tabactiva" v-bind:class="{activa: isActive}" @click="alturaMaxima()">
            <h4>EXPANSIÓN</h4>
          </div>
        </template>

        <div class="">
          <BoletosFirstTeam />
        </div>
      </TabItem>
      <!--<TabItem>
        <template #name>
          <div class="team-type-header">
            <h4 class="disabled">PREMIER</h4>
          </div>
        </template>

        <BoletosPremier />
      </TabItem>
      <TabItem>
        <template #name>
          <div class="team-type-header">
            <h4 class="disabled">TERCERA (TDP)</h4>
          </div>
        </template>

        <BoletosTDP />
      </TabItem>-->
    </Tabs>
  </div>
</template>
<script>
import { Tabs, TabItem } from "vue-material-tabs";
import BoletosFirstTeam from "../components/BoletosFirstTeam";
import BoletosPremier from "../components/BoletosPremier";
import BoletosTDP from "../components/BoletosTDP";
import { mapActions } from "vuex";
let urlpath = require('../global/url');
export default {
  components: {
    Tabs,
    TabItem,
    BoletosFirstTeam,
    BoletosPremier,
    BoletosTDP,
  },
  data() {
    return {
      isActive: true,
      urlpath,
      backgr: []
    }
  },
  async created() {
      let payload2 = {
      id: 'Boletos',
      option: "cabeceras/section",
      };
      this.backgr = await this.getCabecerasById(payload2);
  },
  methods: {
    ...mapActions("main", ["getInfoById"]),
    ...mapActions("cabeceras", ["getCabecerasById"]),
    ...mapActions("cabeceras", ["getCabeceras"]),
    ...mapActions("cabeceras", ["setData"]),
    displayTab(){
      document.getElementById('tabactiva').classList.remove('activa');
      document.getElementById('primer-equipo').style.display = 'none!important';
      document.getElementById('primer-equipo').style.maxHeight = 0;
      document.getElementById('primer-equipo').style.maxWidth = 0;
    },
    alturaMaxima(){
      document.getElementById('primer-equipo').style.maxHeight = 'fit-content';
      document.getElementById('primer-equipo').style.maxWidth = 'fit-content';
    },
  }
};



</script>
<style scoped>
.calendar-cont{
  position: relative;
 /* background-image: url('../assets/boletos-banner.png')!important;
  background-size: 100%;*/
}#calendario .calendar-header {
    height: 24vw;
}
#primer-equipo {
    display: flex!important;
}#calendario .tabla-liga {
    padding: 2vw 0;
    width: 93vw;
}.team-type-header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}.activa {
    color: white!important;
    background: #B70D1D;
    padding-right: 5vw!important;
    background-size: 2.865vw!important;
    background-position-x: 14.115vw!important;
    background-position-y: center;
    background-repeat: no-repeat;
    display: flex;
    background-image: url('../assets/arrow2.png')!important;
}.activa:hover {
    padding-right: 0;
}.disabled{
  pointer-events: none;
}
img.pc.imgx { 
  display: block;
}
  img.mo.imgx { 
    display: none;
}

@media (max-width: 768px) {
.calendar-cont{
  background-image: url('../assets/boletos-banner-mo.png')!important;
}#calendario .calendar-header {
    height: 51vw;
}
img.pc.imgx { 
  display: none;
}
  img.mo.imgx {
    top: 21vw;
  display: block;
    height: 51.208vw;
}
}
</style>