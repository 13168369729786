<template>
  <div class="calendar-first-team">
    <div class="calendar-ft-header">
      <h1>RESULTADOS </h1>
    </div>

    <div class="matches-month">
      <div class="ma-m-header">
        <h3>Septiembre 2021</h3>
      </div>

      <div class="match-ma-m" v-for="(cal, key) in calendarpremier" :key="key">
        <div>

        </div>
        <div class="data-match">
          <h4>{{ date2(cal.descrp) }}</h4>
          <h6>{{ cal.hr }} hrs (hora local)</h6>
        </div>
        <div class="logo-journey-cont">
          <img :src="url+'/get-tournament-image/'+cal.image_tournament" alt="tournament" class="tou-logo" />
          <div class="journey-stadium-cont">
            <h4>{{ cal.typegame }}</h4>
            <h6>{{ cal.stadium }}</h6>
          </div>
        </div>

        <div class="match-m-cont">
          <div class="team-versus" v-if=" cal.stadium == 'Héroe de Nacozari'">
            <div class="cim-team" id="teammx">
              <h6>CIMARRONES DE SONORA</h6>
              <img src='../assets/Cimarrones-mx.png' alt="logo" class="logo-cont" />
            </div>
            <div class="result-versus">
              <h4 class="versus" v-if="cal.descrp >= today()">VS.</h4>
              <h4
                class="match-result-vs"
                id="versa"
                v-else-if="cal.descrp < today()"
              >
                <span>{{ cal.result_c }}</span> -
                <span>{{ cal.result_op }}</span>
              </h4>
            </div>
            <div class="cim-team" id="teammx">
              <img
                :src="url+'/get-calendar-image/'+cal.image"
                alt="logo"
                class="logo-cont"
              />
              <h6>{{ cal.opname }}</h6>
            </div>
          </div>

         <!-- <div class="team-versus" v-else-if="cal.type === 'Visitante'">-->
          <div class="team-versus" v-else>
            <div class="cim-team" id="teammx">
              <h6>{{ cal.opname }}</h6>
              <img
                :src="url+'/get-calendar-image/'+cal.image"
                alt="logo"
                class="logo-cont"
              />
            </div>
            <div class="result-versus">
              <h4 class="versus" v-if="cal.descrp >= today()">VS.</h4>
              <h4
                class="match-result-vs"
                id="versa"
                v-else-if="cal.descrp < today()"
              >
                <span>{{ cal.result_op }}</span> -
                <span>{{ cal.result_c }}</span>
              </h4>
            </div>
            <div class="cim-team" id="teammx">
              <img src='../assets/Cimarrones-mx.png' alt="logo" class="logo-cont" />
              <h6>CIMARRONES DE SONORA</h6>
            </div>
          </div>

          <div class="button-match-cont">
            <a
              href="https://boletomovil.com/cimarrones-sonora"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="l-button bol">BOLETOS</div>
            </a>
          </div>
        </div>
      </div>





      <div class="match-ma-m-mo" v-for="(cal,key) in calendarpremier" :key="key">
        <div class="match-mo" v-if="cal.stadium == 'Héroe de Nacozari'">
          <div class="cim-team">
            <img src='../assets/Cimarrones-mx.png' alt="logo" class="logo-cont" />
            <h6>CIM</h6>
          </div>
          <div class="match-stats">
            <img
              :src="url+'/get-tournament-image/'+cal.image_tournament"
              alt="tournament"
              class="tou-logo"
            />
            <div class="data-match">
              <h4>{{ date2(cal.descrp) }}</h4>
              <h6>{{ cal.hr }} hrs</h6>
            </div>
            <h4 class="match-result-vs" v-if="cal.descrp < today()">
              <span>{{ cal.result_c }}</span> - <span>{{ cal.result_op }}</span>
            </h4>
            <div class="journey-stadium-cont">
              <h4>{{ cal.typegame }}</h4>
              <h6>{{ cal.stadium }}</h6>
            </div>
          </div>
          <div class="cim-team">
            <img
              :src="url+'/get-calendar-image/'+cal.image"
              alt="logo"
              class="logo-cont"
            />
            <h6>{{ cal.opname }}</h6>
          </div>
        </div>

        <div class="match-mo" v-else>
          <div class="cim-team">
            <img
              :src="url+'/get-calendar-image/'+cal.image"
              alt="logo"
              class="logo-cont"
            />
            <h6>{{ cal.opname }}</h6>
          </div>
          <div class="match-stats">
            <img
              :src="url+'/get-tournament-image/'+cal.image_tournament"
              alt="tournament"
              class="tou-logo"
            />
            <div class="data-match">
              <h4>{{ date2(cal.descrp) }}</h4>
              <h6>{{ cal.hr }} hrs</h6>
            </div>
            <h4 class="match-result-vs" v-if="cal.descrp < today()">
              <span>{{ cal.result_op }}</span> - <span>{{ cal.result_c }}</span>
            </h4>
            <div class="journey-stadium-cont">
              <h4>{{ cal.typegame }}</h4>
              <h6>{{ cal.stadium }}</h6>
            </div>
          </div>
          <div class="cim-team">
            <img src='../assets/Cimarrones-mx.png' alt="logo" class="logo-cont" />
            <h6>CIM</h6>
          </div>
        </div>

        <div class="button-match-cont">
          <a
            href="https://boletomovil.com/cimarrones-sonora"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="l-button bol">BOLETOS</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import es from "dayjs/locale/es";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

export default {
  data() {
    return {
      primero: 0,
    };
  },
  created() {
    this.findByLigaTDP();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    calendarpremier() {
      return this.$store.getters["calendar/data6"];
    },
  },
  methods: {
    ...mapActions("calendar", ["findByLigaTDP"]),

    exa() {
      let xd = new Date();
      let fecha =
        xd.getFullYear() + "-" + xd.getDate() + "-" + (xd.getMonth() + 1);
      return fecha;
    },

    today() {
      var now = dayjs();
      return now.format("YYYY-MM-DD");
    },

    dataTransform: function (descrp) {
      let fecha = descrp.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },

    matchday: function (descrp) {
      let fecha = descrp.split("-");
      let dd = fecha[4];
      return (fecha = dd);
    },
    matchmonth: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let match = dayjs(descrp, "YYYY-MM-DD").locale(es).format("DD-MMM-YYYY");
      let fecha = match.split("-");
      let mm = fecha[1];
      return (fecha = mm);
    },

    date1: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let fa = dayjs(descrp, "YYYY-MM-DD").locale(es).format("DD-MM-YYYY");
      let fecha = fa.split("-");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },

    date2: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let fa = dayjs(descrp, "YYYY-MM-DD").locale(es).format("YYYY-MMM-DD");
      let fecha = fa.split("-");
      let mm = fecha[1];
      let dd = fecha[2];
      let fa2 = dayjs(descrp, "YYYY-MM-DD").locale(es).format("YYYY-MMM-ddd");
      let fecha2 = fa2.split("-");
      let dd2 = fecha2[2];
      return (fecha = dd2 + " " + dd + " " + mm);
    },
/*
    opnameMin: function (opname) {
      if (opname == "Tapatío") {
        return "TAP";
      } else if (opname == "Alebrijes de Oaxaca") {
        return "OAX";
      } else if (opname == "Atlante") {
        return "ATL";
      } else if (opname == "Dorados") {
        return "DOR";
      } else if (opname == "Venados F.C.") {
        return "VEN";
      } else if (opname == "Club Atlético Morelia") {
        return "MOR";
      } else if (opname == "RAYA2 EXPANSIÓN ") {
        return "RAY";
      } else if (opname == "TM Futbol Club") {
        return "TAM";
      } else if (opname == "Pumas Tabasco") {
        return "PUM";
      } else if (opname == "Club Celaya") {
        return "CEL";
      } else if (opname == "Tepatitlán FC") {
        return "TEP";
      } else if (opname == "Tlaxcala F.C.") {
        return "TLX";
      } else if (opname == "Cancún FC") {
        return "CAN";
      } else if (opname == "Mineros de Zacatecas") {
        return "MIN";
      } else if (opname == "Leones Negros") {
        return "UDG";
      } else if (opname == "Correcaminos") {
        return "UAT";
      }
    },*/

    findByLigaTDP: async function () {
      let result = await this.$store.dispatch("calendar/findByLigaTDP", {
        option: "calendar/liga", "id": 2
      });
      //console.log(result)
      this.info = result;
      return result;
    },
  },
};
</script>

<style scoped>
.tou-logo {
    width: 5.625vw!important;
    height: auto;
}
</style>
