<template>
  <div class="calendar-first-team">
    <div class="calendar-ft-header">
      <h1>PROXIMOS PARTIDOS</h1>
    </div>

    <div class="matches-month">
      <div class="ma-m-header">
        <!--<h3>Septiembre 2021</h3>-->
      </div>

      <div class="adviser" v-if="this.partidosClub == null">
        <div class="en-construccion-cont">
          <img
            src="../assets/tarjeta-rojiza.png"
            alt="tarjetiza"
            class="tarjetiza"
          />
          <h3 class="titulo-en-constr">
            <span class="red"> VUELVE EN UN RATO.</span> POR EL MOMENTO,<br />
            <b>NO</b> HAY PRÓXIMOS PARTIDOS.
          </h3>
        </div>
      </div>
      <!-- v-if="this.partidosClub.fecha >= today()"-->
      <div class="cal-container" v-else>
        <div v-for="cal in partidosClub" :key="cal.id">
          <div
            class="match-ma-m"
            v-if="cal.clubLocal === 'Cimarrones de Sonora FC'"
          >
            <div class="data-match">
              <h4 v-if="cal.fecha == null">Fecha no disponible</h4>
              <h4 v-else>{{ date2(cal.fecha) }}</h4>
              <h6>{{  horarioFormat(cal.horaLocal) }} hrs <br> (hora local)</h6>
            </div>
            <div class="logo-journey-cont">
          <img v-if="cal.torneo == 'Clausura'" 
           src="../assets/clau22.jpeg" alt="tournament" class="tou-logo" />
          <!-- <div class="journey-stadium-cont">
         <img v-if="cal.torneo == 'Clausura' && calendardata.name_tournament[i] == 'Clausura'" 
          src="../assets/grita-mx.png" alt="tournament" class="tou-logo" />-->
              <div class="journey-stadium-cont">
                <h4>{{ cal.jornada }}</h4>
                <h6>{{ cal.estadio }}</h6>
              </div>
            </div>

            <div class="match-m-cont">
              <div
                class="team-versus"
                v-if="cal.clubLocal === 'Cimarrones de Sonora FC'"
              >
                <div class="cim-team" id="teammx">
                  <h6>CIMARRONES DE SONORA</h6>
                  <img :src="cal.clubLocalLogo" alt="logo" class="logo-cont" />
                </div>
                <div class="result-versus">
                  <h4 class="versus" v-if="cal.fecha >= today()">VS.</h4>
                  <h4 class="versus" v-else-if="cal.horaLocal == '00:00:00'">VS.</h4>
                  <h4
                    class="match-result-vs"
                    id="versa"
                    v-else-if="cal.fecha < today()"
                  >
                    <span>{{ cal.golLocal }}</span> -
                    <span>{{ cal.golVisita }}</span>
                  </h4>
                </div>
                <div class="cim-team vis" id="teammx">
                  <img :src="cal.clubVisitaLogo" alt="logo" class="logo-cont" />
                  <h6>{{ cal.clubVisita }}</h6>
                </div>
              </div>

              <div class="button-match-cont">
                <a
                  :href="boletos(cal.fecha)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="l-button bol">COMPRAR BOLETOS</div>
                </a>
              </div>
            </div>
          </div>
        <div v-else>

        </div>
        </div>

        <div v-for="cal in partidosClub" :key="cal.id">
          <div class="match-ma-m-mo" v-if="cal.clubLocal === 'Cimarrones de Sonora FC'">
            <div
              class="match-mo"
              v-if="cal.clubLocal === 'Cimarrones de Sonora FC'"
            >
              <div class="cim-team">
                <img :src="cal.clubLocalLogo" alt="logo" class="logo-cont" />
                <h6>CIM</h6>
              </div>
              <div class="match-stats">
          <img v-if="cal.torneo == 'Clausura'" 
           src="../assets/clau22.jpeg" alt="tournament" class="tou-logo" />
          <!-- <div class="journey-stadium-cont">
         <img v-if="cal.torneo == 'Clausura' && calendardata.name_tournament[i] == 'Clausura'" 
          src="../assets/grita-mx.png" alt="tournament" class="tou-logo" />-->
                <div class="data-match">
                  <h4 v-if="cal.fecha == null">Fecha no disponible</h4>
                  <h4 v-else>{{ date2(cal.fecha) }}</h4>
                  <h6>{{  horarioFormat(cal.horaLocal) }} hrs <br> (hora local)</h6>
                </div>
                <h4 class="match-result-vs" v-if="cal.fecha < today()">
                  <span>{{ cal.golLocal }}</span> -
                  <span>{{ cal.golVisita }}</span>
                </h4>
                <div class="journey-stadium-cont">
                  <h4>{{ cal.jornada }}</h4>
                  <h6>{{ cal.estadio }}</h6>
                </div>
              </div>
              <div class="cim-team vis">
                <img :src="cal.clubVisitaLogo" alt="logo" class="logo-cont" />
                <h6>{{ opnameMin(cal.clubVisita) }}</h6>
              </div>
            </div>

            <div class="button-match-cont">
              <a
                :href="boletos(cal.fecha)"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="l-button bol">COMPRAR BOLETOS</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import es from "dayjs/locale/es";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

export default {
  data() {
    return {
      primero: 0,
    };
  },
  created() {
    this.getCalendar1();
    let payload2 = {
      idTorneo: 1,
      idDivision: 3,
    };
    this.getJornadas(payload2);

    let payload3 = {
      idTorneo: 1,
      idDivision: 2,
      idJornada: 16,
    };
    this.getPartidos(payload3);

    let payload4 = {
      idTorneo: 2,
      idDivision: 20,
      idTemporada: 74,
      idClub: 10721,
    };

    this.getPartidosClub(payload4);
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    calendardata() {
      return this.$store.getters["calendar/data"];
    },
    jornadas() {
      return this.$store.getters["jornadas/data"];
    },
    partidos() {
      return this.$store.getters["partidos/data"];
    },
    partidosClub() {
      let arr = this.$store.getters["partidos/data2"];
      let narr = []
      dayjs.extend(customParseFormat);
      let date = dayjs().format("YYYY-MM-DD");
      for (let i = 0; i < arr.length; i++) {
        if(arr[i].fecha > date){
          narr.push(arr[i])
        }
      }
      return narr
    },
  },
  methods: {
    ...mapActions("calendar", ["getCalendar1"]),
    ...mapActions("jornadas", ["getJornadas"]),
    ...mapActions("partidos", ["getPartidos"]),
    ...mapActions("partidos", ["getPartidosClub"]),

    boletos(fecha){
          if (fecha == '2022-02-13') {
              find = true;
              return 'https://boletomovil.com/evento/paquete-cimarrones-vs-leones-negros-y-alebriges-22-02-12';
          }else if (fecha == '2022-02-16') {
              find = true;
              return 'https://boletomovil.com/evento/paquete-cimarrones-vs-leones-negros-y-alebriges-22-02-12';
          }else{
              find = true;
              return 'https://boletomovil.com/cimarrones-sonora';
          }
    },

    exa() {
      let xd = new Date();
      let fecha =
        xd.getFullYear() + "-" + xd.getDate() + "-" + (xd.getMonth() + 1);
      return fecha;
    },

    today() {
      var now = dayjs();
      return now.format("YYYY-MM-DD");
    },

    dataTransform: function (descrp) {
      let fecha = descrp.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },

    matchday: function (descrp) {
      let fecha = descrp.split("-");
      let dd = fecha[4];
      return (fecha = dd);
    },
    matchmonth: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let match = dayjs(descrp, "YYYY-MM-DD").locale(es).format("DD-MMM-YYYY");
      let fecha = match.split("-");
      let mm = fecha[1];
      return (fecha = mm);
    },

    date1: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let fa = dayjs(descrp, "YYYY-MM-DD").locale(es).format("DD-MM-YYYY");
      let fecha = fa.split("-");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },

    date2: function (descrp) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let fa = dayjs(descrp, "YYYY-MM-DD").locale(es).format("YYYY-MMM-DD");
      let fecha = fa.split("-");
      let mm = fecha[1];
      let dd = fecha[2];
      let fa2 = dayjs(descrp, "YYYY-MM-DD").locale(es).format("YYYY-MMM-ddd");
      let fecha2 = fa2.split("-");
      let dd2 = fecha2[2];
      return (fecha = dd2 + " " + dd + " " + mm);
    },

    horarioFormat: function(hora){
      let gazir = hora.split(":");
      let h = gazir[0];
      let m = gazir[1];
      return h+":"+m;
    },

    opnameMin: function (opname) {
      if (opname == "Tapatío") {
        return "TAP";
      } else if (opname == "Alebrijes de Oaxaca") {
        return "OAX";
      } else if (opname == "Atlante") {
        return "ATL";
      } else if (opname == "Dorados") {
        return "DOR";
      } else if (opname == "Venados F.C.") {
        return "VEN";
      } else if (opname == "Club Atlético Morelia") {
        return "MOR";
      } else if (opname == "RAYA2 EXPANSIÓN ") {
        return "RAY";
      } else if (opname == "TM Futbol Club") {
        return "TAM";
      } else if (opname == "Pumas Tabasco") {
        return "PUM";
      } else if (opname == "Club Celaya") {
        return "CEL";
      } else if (opname == "Tepatitlán FC") {
        return "TEP";
      } else if (opname == "Tlaxcala F.C.") {
        return "TLX";
      } else if (opname == "Cancún FC") {
        return "CAN";
      } else if (opname == "Mineros de Zacatecas") {
        return "MIN";
      } else if (opname == "Leones Negros") {
        return "UDG";
      } else if (opname == "Correcaminos") {
        return "UAT";
      }
    },

    getCalendar1: async function () {
      let result = await this.$store.dispatch("calendar/getCalendar1", {
        option: "calendario",
      });
      //console.log(result)
      this.info = result;
      return result;
    },
  },
};
</script>

<style scoped>
.matches-month {
  align-items: center !important;
  padding-bottom: 6.615vw;
}
</style>
