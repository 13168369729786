<template>
    <div id="calendario">
         <div id="headerHome" class="col-sm-12 col-md-12  color-bg-1">
                <HeaderHome :name="user.fullname" /> 
        </div>
        
        <BoletoCont />
        
         <div id="footerSection">
             <Footer />
         </div>
    </div>
</template>
<script>
import Footer from '../components/footer';
import HeaderHome from '../components/HeaderHome';
import BoletoCont from '../components/BoletoCont';

export default {
    components: {
        HeaderHome,
        Footer,
        BoletoCont,
    },
    computed: {
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
    },


  /**titulo pagina */
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Boleto - Cimarrones de Sonora';
            }
        },
    }
    
}
</script>

<style>
#tabactiva {
    display: none !important;
}
</style>